.Container {
    height: 100vh;
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
    
}

.Box1 {
    margin-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    padding: 20px;
    /* margin: auto; */
    /* border: red solid 1px; */
}

.Box1 img {
    box-shadow:0 6px 15px rgb(71, 71, 71);
    width: 80%;
    height: auto;
    object-fit: cover;
    
}

.Box2 {
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
   /* margin: auto; */
   /* border: red solid 1px; */
}

.Box2 h2{
    font-size: 2rem;
    font-family: 'Poiret One', cursive;
    font-weight: lighter;
    text-align: center;

}
.Box2 p{
    font-size: 1rem;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 1px;
    padding-top: 50px;
}

@media screen and (max-width: 950px) {
    .Container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding-bottom: 60px;
        /* border: rgb(30, 209, 30) solid 2px; */
    }
    .Box1 {
        margin-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        padding: 30px;
        margin: 0;
        height: 40%;
        /* border: red solid 1px; */
    }
    
    .Box1 img {
        box-shadow:0 6px 15px rgb(71, 71, 71);
        width: auto;
        height: 100%;
        object-fit: cover;
        
    }
    
    .Box2 {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: justify;
       /* margin: auto; */
       /* border: red solid 1px; */
    }
    
    .Box2 h2{
        font-size: 2rem;
        font-family: 'Poiret One', cursive;
        font-weight: lighter;
        text-align: center;
    
    }
    .Box2 p{
        font-size: 1rem;
        font-family: 'Raleway', sans-serif;
        letter-spacing: 1px;
        padding-top: 50px;
    }
    
}







