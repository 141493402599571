.Footer-container{
    background: black;
    height: 20vh;
    display: flex;
    flex-direction: column;
    color: rgb(202, 202, 202);
}
.Footer-container div {
    margin: auto;
}

.Footer-container .Footer-title {
    text-align: center;
}

.Footer-container .Footer-title p {
    color: rgb(133, 133, 133);
    font-size: 1.5rem;
    font-weight: bold;
}
.Footer-container .Footer-title span {
    color: rgb(194, 194, 194);
}

.Footer-container .Footer-title .Copyright p {
    font-size: .8rem;
    color: rgb(109, 109, 109);
}








