.Nav-container{
    font-family: 'Poiret One', cursive;
    display: flex;    
    background: transparent;
    position: sticky;
    top: 0;
    z-index: 10;
    justify-content: center;
    height: 80px;
    transition: all 0.4s ease-in-out;
}
.Nav-container .hamburger{
    display: none;
    width: 35px;
    height: 35px;
}

nav{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    align-items: center;
}



nav h2 {
    letter-spacing: 3px;
    cursor: pointer;
    color: gray;
    /* display: flex; */
    /* flex: 2; */
}
nav h2 span{
    color: rgb(180, 180, 180);
}

nav ul {
    display: flex;
    text-decoration: none;
    list-style: none;
}
nav ul  a{
    text-decoration: none;
    padding: 2rem;
    color: white;
    letter-spacing: 0.15em;
    cursor: pointer;

}

nav ul li > .active {
    color:rgb(179, 179, 179);
    /* text-decoration: underline;
    text-underline-offset:6px; */
}

.Nav-container.active {
    background: rgb(20, 20, 20);
    transition: all 0.4s ease-in-out;
    
}

@media screen and (max-width: 950px) {
    .Nav-container {
        display: flex;
        width: 100vw;
        height: 50px;
        /* border: rgb(217, 255, 0) solid 2px; */
    }
    
    .Nav-container nav{
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* align-items: center; */
        /* border: rgb(242, 242, 250) solid 1px; */
    }
    .Nav-container nav h2 {
        /* border: red solid 1px; */
    }
    /* .Nav-container ul{
        width: 100vw;
        height: 100vh;
        display: block;
        background-color: red ;
        transition: ease .5s;
    } */
    .Nav-container .hamburger{
        display: flex;
        position: fixed;
        top: 5px;
        right: 5px;
    }
    .Nav-container .close{
        display: flex;
        position: fixed;
        top: 5px;
        right: 5px;
    }
    .Nav-container .hidden-menu{
        width: 100vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        position: fixed;
        right: -100vw;
        top: 0;
        transition: ease .5s;
        background-color: rgba(26, 26, 26, 0.98) ;
        padding-top: 50px;
    }
    .Nav-container .active-menu{
        width: 100vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 0;
        transition: ease .5s;
        background-color: rgba(26, 26, 26, 0.98) ;
        padding-top: 50px;
    }
    .active-menu li{
        padding: 10px 0;
    }
    .active-menu li a{
        padding: 10px 30px;
    }
    .hidden-menu li{
        padding: 10px 0;
    }
    .hidden-menu li a{
        padding: 10px 30px;
    }
    .active-h2 {
        display: none;
        transition: ease-in-out .2s;
    }
    nav ul li > .active {
        color:rgb(122, 121, 121);
        text-decoration: none;
        
    }
}