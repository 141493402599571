.Hero-container{
    /* position: relative; */
    margin-top: -80px;
    /* height: 100vh; */
    /* display: flex;
    min-height: 100vh;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center; */


}
.up-button{
    position: fixed;
    right: 10px;
    bottom: 50px;
    width: 30px;
    height: 30px;
    z-index: 999;
    transition: ease .3s;
    background: rgba(83, 83, 83, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}
.up-arrow{
    width: 70%;
    height: auto;

}
/* .up-button button{
    width: 100%;
    height: 100%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
} */

.hidden-button{
    position: fixed;
    right: -90px;
    bottom: 50px;
    width: 40px;
    height: 40px;
    z-index: 999;
    transition: ease .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}
.Hero-bg{
    display: flex;
    min-height: 100vh;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url('../../img/bg-1-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.ScrollUpButton__Container {
    position: fixed;
    right: -100px;
    bottom: 150px;
    transition: right 0.5s;
    cursor: pointer;
    background-color: rgb(204, 7, 7);
    font-size: 20px;
    padding: 10px;
}

@media screen and (max-width: 960px){
    .Hero-container {
        display: flex;
        /* border: rgb(247, 0, 255) solid 3px; */
        height: 100vh;
        margin-top: -80px;
        width: 100vw;
        background-color: black;
    }
    .Hero-container .Hero-bg{
        /* border: rgb(0, 255, 106) solid 3px; */
        z-index: 5;
        display: flex;
        min-height: 50%;
        width: 100vw;
        
        background: url('../../img/bg-6.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center ;
    }
    .up-button{
       
        width: 40px;
        height: 40px;
       
    }
}