.Kids-container{
    background: rgb(16, 27, 36);
    padding: 120px 10%;
    margin-top: -80px;
}
.Kids-container .title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    

}
.Kids-container .title h1 {
    padding: 5px;
    text-align: center;
    width: 400px;
    color: white;
    font-size: 2rem;
    font-weight: lighter;
    border-bottom: white solid 1px;
    letter-spacing: 5px;
}
.Kids-photos{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(10, 13vw);
    /* grid-gap: 15px; */
}
.Kids-Container .single-image{
    display: none;
}
.gallery-item{
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    overflow: hidden;
    
}
.gallery-item img{
    opacity: .9;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ease .2s;
    cursor: url("../../img/zoom-in-thick.svg"), auto;
}
.gallery-item img:hover{
    border: white solid 1px;
    transform: scale(1.05);
    opacity: 1;
    
}
.gallery-item-0 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}
.gallery-item-1 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}
.gallery-item-2 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery-item-5 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
}
.gallery-item-6 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
}
.gallery-item-7 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 5;
}
.gallery-item-8 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 7;
}


.gallery-item-9 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 7;
}
.gallery-item-10 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 5;
    grid-row-end: 7;
}
.gallery-item-11 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 9;
}
.gallery-item-12 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 7;
    grid-row-end: 9;
}
.gallery-item-13 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 9;
}
.gallery-item-14 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 9;
    grid-row-end: 11;
}
.gallery-item-15 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 9;
    grid-row-end: 11;
}
.gallery-item-16 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 9;
    grid-row-end: 11;
}
.gallery-item-17 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 11;
    grid-row-end: 13;
}
.gallery-item-18 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 11;
    grid-row-end: 13;
}
.gallery-item-19 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 11;
    grid-row-end: 13;
}
.gallery-item-20 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 13;
    grid-row-end: 15;
}
.gallery-item-21 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 13;
    grid-row-end: 15;
}
.gallery-item-22 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 13;
    grid-row-end: 15;
}

.none{
    display: none;
}
.fullscreen-image{
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgb(10, 25, 32);
    width: 100vw;
    height: 100vh;
    color:  white;
}
.fullscreen-image img{
    width: auto;
    height: 85%;
    transition: ease 1s;
    transition-duration: ease .5s;
    /* border: white solid 1px; */
}
/* .first-row{
   height: 400px;
   width: 100%;
   display: grid;
   grid-template-columns: 2fr 1fr 1fr;
   border: red solid 1px;
} */

/* .normal{    
    z-index: 9;
    width: auto;
    height: 400px;
    object-fit: contain;
} */
/* .normal img{
    height: 100%;
} */
/* .fullscreen{
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgba(0,0,0,0.2);
    width: 100vw;
    height: 100vh;
    transition:  ease .1s;
}
.fullscreen img{
    width: auto;
    height: 85%;
} */


.next {
    position: absolute;
    right: 0;
    height: 100vh;
    width: 50vw;
    background: none;
    border: none;
    color: rgb(117, 115, 115);
    font-size: 4rem;
    transition: ease .3s;
    cursor: pointer;text-align: right;
    cursor: url("../../img/right-2.svg"), auto;
}
.previous {
    position: absolute;
    left: 0;
    height: 100vh;
    width: 50vw;
    background: none;
    border: none;
    color: rgb(117, 115, 115);
    font-size: 4rem;
    transition: ease .3s;
    cursor: pointer;
    text-align: left;
    cursor: url("../../img/left-2.svg"), auto;
    }
.close {
    position: absolute;
    bottom: 10px;
    height: 50px;
    width: 80px;
    background: none;
    border: none;
    color: rgb(185, 183, 183);
    font-size: 1.2rem;
    letter-spacing: 5px;
    font-weight: 600;
    transition: ease .3s;
    cursor: pointer;
    font-family: 'Poiret One', cursive;
    text-transform: uppercase;
}
.fullscreen-image  button:hover{
    color: rgb(243, 240, 240);
    transition: ease .3s;
}
.fullscreen-image  button:focus{
    outline: none;
}
/* .first-row #img2{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.first-row #img3{
    width: 100%;
    height: auto;
    object-fit: cover;
} */
.second-row{
    border: red solid 1px;
}
.third-row{
    border: red solid 1px;
}
.forth-row{
    border: red solid 1px;
}

@media screen and (max-width: 950px){
    .Kids-photos{
        display: flex;
        flex-direction: column;
        
    }
    .fullscreen-image{
        display: flex;
        justify-content: center;
        align-items: center;
        background:rgb(10, 25, 32);
        width: 100vw;
        height: 100vh;
        color:  white;
    }
    .fullscreen-image img{
        
        
        width: 100%;
        height: auto;
        
    }
    .close {
        position: absolute;
        top: 20px;
        
        height: 50px;
        width: 80px;
        background: none;
        border: none;
        color: rgb(185, 183, 183);
        font-size: 1.2rem;
        letter-spacing: 5px;
        font-weight: 600;
        transition: ease .3s;
        cursor: pointer;
        font-family: 'Poiret One', cursive;
        text-transform: uppercase;
    }
}










