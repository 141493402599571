
  .Gallery2 {
      position: relative;
      height: 100vh;
      background: rgb(0, 0, 0);
  }
  .Gallery2 ul {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    list-style:none;
    width:auto;
    height:80vh;
    border-radius:3px;
    overflow:hidden;
    box-shadow:1px 1px 14px 0px #fff;
  }
  
  
  .Gallery2 li {
    position:relative;
    width:20%;
    height:80vh;
    float:left;
    border-left:1px solid white;
    -webkit-transition:all 0.7s;
    -moz-transition:all 0.7s;
    transition:all 0.7s;
    box-shadow:-2px 0 10px 2px;
    
  }
  
  .Gallery2 ul li:first-child {
    border:none;
  }
  
  .Gallery2 span {
    display:block;
    position:absolute;
    bottom:0;
    width:50%;
    color:white;
    white-space:nowrap;
    padding:20px;
    background: -moz-linear-gradient(left,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* IE10+ */
    background: linear-gradient(to right,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
    
  }
  
  .Gallery2 img {
        width:auto;
        height:80vh;
        background-size: cover;
  }
  
  .Gallery2 ul:hover li {
    width:10%;  
  }
  
  
  .Gallery2 ul li:hover {
    width:40vw;
  }
  
  
  
  @media screen and (max-width: 950px){
    .Gallery2 {
      display: none;
      margin-top:86%;
      width: 100vw;
    }
  }
  
  