.Gallery-container{
    background: #0c0c0c;
    display: relative;
    justify-content: center;
    /* align-items: center; */
    height: 100vh; 
    position: relative;
    z-index: 1;
    color: #fff;
}



.Gallery-container h1 {
        letter-spacing: 0.2em;
        font-family: 'Poiret One', cursive;
        margin:  auto;
        padding:  10px ;
        justify-content: center;
        text-align: center;
        align-items: center;        
        font-size: 2.3rem;
        font-weight: 100;
        max-width: 900px;
        position: relative;
        color:rgb(255, 255, 255);
        }
    
        .Gallery-container h1:before {
    
        content: "";
        display: block;
        width: 330px;
        height: 1px;
        position: absolute;
        background: rgb(255, 255, 255);
        left:0;
        top: 50%;
    }
    
    .Gallery-container  h1:after {
        content: "";
        display: block;
        width: 330px;
        height: 1px;
        position: absolute;
        background: rgb(255, 255, 255);
        right: 0;
        top: 50%;
    }
    
    p {
        font-size: .8rem;
        letter-spacing: 0;
    
    }

.Photo-gallery{
    flex-direction: row;
    margin-top: 50px;
    display: flex;
    height: 70%;
    width: 100%;    
    
}

/* .Gallery-info{
    justify-content: center;
    align-items: center;
    text-align: center;
} */
.Gallery-info p {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 1rem;
    align-items: center;    
}

.Photo-item-1{  
    position: relative;
    flex: 1;   
    align-items: center;
    transition: transform 2s;
    overflow: hidden;
    
}
.Photo-item-1 .img {
    position: absolute;
    min-width: 1000%;
    min-height: 1000%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.1);
    z-index: -1;    
    background: url('../../img/img1-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-1 .img2 {
    position: absolute;
    min-width: 1000%;
    min-height: 1000%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.1);
    z-index: -1;    
    background: url('../../img/img2-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-1 .img3 {
    position: absolute;
    min-width: 1000%;
    min-height: 1000%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.1);
    z-index: -1;    
    background: url('../../img/img3-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-1 .img4 {
    position: absolute;
    min-width: 1000%;
    min-height: 1000%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.1);
    z-index: -1;    
    background: url('../../img/img4-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-1 .img5 {
    position: absolute;
    min-width: 1000%;
    min-height: 1000%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.1);
    z-index: -1;    
    background: url('../../img/img5-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-1 .Overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba( 0,0,0,0.3);
}
.Photo-item-1 .Overlay2 {
    position: absolute;
    top: 0;
    left:-250%;
    width: 150%;
    height: 100%;
    background-color: rgba( 0,0,0,0.7);
    transform: skewX( -30deg);
    transition: .5s;
}
.Photo-item-1:hover .Overlay2 {
    left: -10%;
}

.Photo-item-1 .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    color: black;
    box-sizing: border-box;
    padding: 25px;
    z-index: 1000;
    text-shadow: 5px 5px 10px black;

}


.Photo-item-1 .text span:nth-child(1) {
    position: absolute;
    top: 0;
    left: -20px;
    width: 0;
    height: 1px;
    background-color: rgb(255, 255, 255);
    transform-origin: left;
    transition:  .3s ease-in-out;
    transition-delay: .3s;
}
.Photo-item-1 .text span:nth-child(2) {
    position: absolute;
    top: -20px;
    left: 0;
    width: 1px;
    height: 0;
    background-color: white;
    transform-origin: top;
    transition:  .3s ease-in-out;
    transition-delay: .5s;
}
.Photo-item-1 .text span:nth-child(3) {
    position: absolute;
    bottom: 0;
    right: -20px;
    width: 0;
    height: 1px;
    background-color: white;
    transform-origin: right;
    transition:  .3s ease-in-out;
    transition-delay: .3s;
}
.Photo-item-1 .text span:nth-child(4) {
    position: absolute;
    bottom:-20px;
    right: 0;
    width: 1px;
    height: 0;
    background-color: white;
    transform-origin: left;
    transition:  .3s ease-in-out;
    transition-delay: .5s;
}


.Photo-item-1:hover .text span:nth-child(1),
.Photo-item-1:hover .text span:nth-child(3) {
    width: 115%;
}
.Photo-item-1:hover .text span:nth-child(2),
.Photo-item-1:hover .text span:nth-child(4) {
    height: 120%;
}

.Photo-item-1 .text h2 {
    text-align: center;
    position: relative;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    opacity: 0;
    transition: .5s;
    transition-timing-function: linear;
    transform: translateY(-10px);   
}

.Photo-item-1:hover .text h2 {
    text-align: center;
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.6s;
}


.Photo-item-1 .text p {
    text-align: center;
   line-height: 1.2; 
   opacity: 0;
   transform: translateY(10px);
   transition: .5s;
   transition-timing-function: linear;
   color: white;

}

.Photo-item-1:hover .text p {
    text-align: center;
    transform: translateY(0);
    opacity: 1;
    transition-delay: .7s;
}
.Photo-item-2{  
    margin: 0;
    flex: 1;    
    background: url('../../img/img2-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-3{    
    flex: 1;   
    background: url('../../img/img3-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-4{    
    flex: 1;   
    background: url('../../img/img4-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.Photo-item-5{    
    flex: 1;
    background: url('../../img/img5-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}


.Gallery-info h2 {
    font-family: 'Poiret One', cursive;
    font-weight: lighter;
    color: white;

}

@media screen and (max-width: 950px){
    .Gallery-container{
        background: #0c0c0c;
        display: grid;
        grid-template-columns: 100%;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        height: 320vh;
        width: 100%; 
        padding: 5%;
        /* margin-top: 300%; */
    }
   
    .Gallery-container h1:before{
        display: none;
        height: 0;
    }
    .Gallery-container h1:after{
        display: none;
        height: 0;
    }
    .Gallery-container .Photo-gallery {
        margin: 0;
    }
    .Photo-gallery{
        display: flex;
        flex-direction: column;
        height: 300vh;
        width: 100%;  
        
    }
    .Photo-gallery .Photo-item-1 {
        height: 150px;
    }
    
    .Photo-item-1 .img {
        position: absolute;
        min-width: 900%;
        min-height: 900%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.1);
        z-index: -1;    
        background: url('../../img/img1-2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center ;
    }
    .Photo-item-1 .img2 {
        position: absolute;
        min-width: 900%;
        min-height: 900%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.1);
        z-index: -1;    
        background: url('../../img/img2-2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center ;
    }
    .Photo-item-1 .img3 {
        position: absolute;
        min-width: 900%;
        min-height: 900%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.1);
        z-index: -1;    
        background: url('../../img/img3-2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center ;
    }
    .Photo-item-1 .img4 {
        position: absolute;
        min-width: 900%;
        min-height: 900%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.1);
        z-index: -1;    
        background: url('../../img/img4.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center ;
    }
    .Photo-item-1 .img5 {
        position: absolute;
        min-width: 900%;
        min-height: 900%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.1);
        z-index: -1;    
        background: url('../../img/img5-2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center ;
    }
    .Photo-item-1 .Overlay2 {
        position: absolute;
        top: 0;
        left:-250%;
        width: 120%;
        height: 100%;
        background-color: rgba( 0,0,0,0.7);
        transform: skewX( -30deg);
        transition: .5s;
    }
    .Photo-item-1:hover .Overlay2 {
        left: -10%;
    }
    
    .Photo-item-1 .img2{
        border: white solid 8px;
    }
    .Photo-item-1 .img{
        border: white solid 8px;
    }
    .Photo-item-1 .img3{
        border: white solid 8px;
    }
    .Photo-item-1 .img4{
        border: white solid 8px;
    }
    .Photo-item-1 .img5{
        border: white solid 8px;
    }
    .Photo-item-1 .img:hover {
        filter: grayscale(0);
        border: red solid 10px;
    }
}



